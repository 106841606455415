import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ElectricityMeter } from '@smooved-lib/lib/meters/types/electricity-meter';
import { ElectricityMeterService } from '@smooved-lib/lib/meters/services/electricity-meter.service';

type ElectricityMeterState = {
    isLoading: boolean;
    meter: ElectricityMeter;
};

const defaultState: ElectricityMeterState = {
    isLoading: false,
    meter: null,
};

export const ElectricityMeterStore = signalStore(
    { providedIn: 'root' },
    withState(defaultState),
    withMethods((store, electricityMeterService = inject(ElectricityMeterService)) => ({
        async load(transactionId: string) {
            patchState(store, { isLoading: true });
            try {
                const meter = await firstValueFrom(electricityMeterService.getMeter(transactionId));
                patchState(store, {
                    isLoading: false,
                    meter,
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
        async patch(transactionId: string, payload: ElectricityMeter) {
            patchState(store, { isLoading: true });
            try {
                const meter = await firstValueFrom(electricityMeterService.patchMeter(transactionId, payload));
                patchState(store, {
                    isLoading: false,
                    meter,
                });
            } catch (_) {
                patchState(store, { isLoading: false });
            }
        },
    }))
);

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URI_CONFIG, BaseUriConfig } from '@smooved/core';
import { Observable } from 'rxjs';
import { ElectricityMeter } from '@smooved-lib/lib/meters/types/electricity-meter';

@Injectable({
    providedIn: 'root',
})
export class ElectricityMeterService {
    private readonly httpClient = inject(HttpClient);
    private readonly baseUriConfig: BaseUriConfig = inject(BASE_URI_CONFIG);

    public getMeter(transactionId: string): Observable<ElectricityMeter> {
        return this.httpClient.get<ElectricityMeter>(`${this.baseUriConfig.apiUri}/transaction/${transactionId}/meters/electricity`);
    }

    public patchMeter(transactionId: string, payload: ElectricityMeter): Observable<ElectricityMeter> {
        return this.httpClient.patch(`${this.baseUriConfig.apiUri}/transaction/${transactionId}/meters/electricity`, payload);
    }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URI_CONFIG, BaseUriConfig } from '@smooved/core';
import { Observable } from 'rxjs';
import { GasMeter } from '@smooved-lib/lib/meters/types/gas-meter';

@Injectable({
    providedIn: 'root',
})
export class GasMeterService {
    private readonly httpClient = inject(HttpClient);
    private readonly baseUriConfig: BaseUriConfig = inject(BASE_URI_CONFIG);

    public getMeter(transactionId: string): Observable<GasMeter> {
        return this.httpClient.get<GasMeter>(`${this.baseUriConfig.apiUri}/transaction/${transactionId}/meters/gas`);
    }

    public patchMeter(transactionId: string, payload: GasMeter): Observable<GasMeter> {
            return this.httpClient.patch(`${this.baseUriConfig.apiUri}/transaction/${transactionId}/meters/gas`, payload);
        }
}

import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GasMeter } from '@smooved-lib/lib/meters/types/gas-meter';
import { GasMeterService } from '../services/gas-meter.service';

type GasMeterState = {
    isLoading: boolean;
    meter: GasMeter;
};

const defaultState: GasMeterState = {
    isLoading: false,
    meter: null,
};

export const GasMeterStore = signalStore(
    { providedIn: 'root' },
    withState(defaultState),
    withMethods((store, gasMeterService = inject(GasMeterService)) => ({
        async load(transactionId: string) {
            patchState(store, { isLoading: true });
            try {
                const meter = await firstValueFrom(gasMeterService.getMeter(transactionId));
                patchState(store, {
                    isLoading: false,
                    meter,
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
        async patch(transactionId: string, payload: GasMeter) {
            patchState(store, { isLoading: true });
            try {
                const meter = await firstValueFrom(gasMeterService.patchMeter(transactionId, payload));
                patchState(store, {
                    isLoading: false,
                    meter,
                });
            } catch (_) {
                patchState(store, { isLoading: false });
            }
        },
    }))
);
